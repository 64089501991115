<template>
  <span
    class="empty-ph-small text-nowrap"
    :class="{
      'moneyfield_crossout': crossout,
    }"
  >
    <span v-if="value === undefined">/</span>
    <span
      v-else
      :class="{
        'is-size-4': size === 'lg',
        'font-weight-bold': accent,
      }"
    >{{numToPriceFormat(value, lang, true)[0]}}</span>
    <template v-if="!dynamicCents || numToPriceFormat(value, lang, true)[2] !== '00'">
      <span>{{numToPriceFormat(value, lang, true)[1]}}</span>
      <span>{{numToPriceFormat(value, lang, true)[2]}}</span>
    </template>
    {{ currency }}
  </span>
</template>
<script>
import { mapState } from 'vuex';
import { numToPriceFormat } from '@/common/snippets';

export default {
  props: {
    value: [String, Number],
    size: {
      type: String,
      default: 'md', /** can be lg */
    },
    accent: Boolean, /** bold before delimiter */
    dynamicCents: Boolean, /** cents hidden when its 0 */
    currency: {
      type: String,
      default: '',
    },
    crossout: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(['lang']),
  },
  methods: {
    numToPriceFormat,
  },
};
</script>
<style lang="scss">
.moneyfield {
  &_crossout {
    text-decoration: line-through;
    color: var(--red);
    font-size: 0.9em;
    font-weight: normal;
  }
}
</style>
